@import url('https://fonts.googleapis.com/css2?family=Overpass&display=swap');

html,
body {
  min-width: 300px;
}

header,
main,
footer {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #33bccd;
}
